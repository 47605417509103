
const text = `<div class=WordSection1 style='layout-grid:15.6pt'>
<h1 align=center style='text-align:center;mso-pagination:widow-orphan'><span
style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:微软雅黑;color:#333333'>知识产权声明<span lang=EN-US><o:p></o:p></span></span></h1>

<p align=center style='text-align:center;line-height:18.75pt;mso-pagination:
widow-orphan'><span style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'>最新版本生效日期：<span lang=EN-US>2020</span>年<span lang=EN-US>5</span>月<span
lang=EN-US>8</span>日<span lang=EN-US><o:p></o:p></span></span></p>
<p class=MsoNormal align=center style='margin-right:18.3pt;text-align:center;
mso-line-height-rule:exactly;border-bottom:1px solid #dfdfdf;'>
<span style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>
<br/></p>
<p style='text-indent:21.0pt;line-height:18.75pt;mso-pagination:widow-orphan'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:Tahoma;
mso-bidi-font-family:Tahoma;color:#333333'>本网站（<span lang=EN-US>www.yinzhilv.com</span>）所有的产品、技术、程序、页面（包括但不限于页面设计及内容）以及资料内容（包括但不限于本站所刊载的图片、视频、<span
lang=EN-US>Flash</span>等）均属于知识产权，<b style='mso-bidi-font-weight:normal'>仅供本用户交流、学习、研究和欣赏，未经授权，任何人不得擅自使用</b>，否则将依法追究法律责任。<span
lang=EN-US><o:p></o:p></span></span></p>

<p style='text-indent:21.0pt;line-height:18.75pt;mso-pagination:widow-orphan'><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:Tahoma;mso-bidi-font-family:Tahoma;color:#333333'>本网站用户在攻略及论坛频道上传的资料内容（包括但不限于图片、视频、<span
lang=EN-US>Flash</span>、点评等），应保证为原创或已得到充分授权</span></b><span style='font-size:
10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:Tahoma;mso-bidi-font-family:
Tahoma;color:#333333'>，并具有准确性、真实性、正当性、合法性，且不含任何侵犯第三人权益的内容，因抄袭、转载、侵权等行为所产生的纠纷由用户自行解决，本网站不承担任何法律责任。<span
lang=EN-US><o:p></o:p></span></span></p>

<p style='text-indent:21.0pt;line-height:18.75pt;mso-pagination:widow-orphan'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:Tahoma;
mso-bidi-font-family:Tahoma;color:#333333'>“<span class=GramE>胤</span>之旅同业平台”、“<span
class=GramE>胤</span>之旅</span><span lang=EN-US style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:Tahoma;mso-bidi-font-family:Tahoma;
color:#404040;mso-themecolor:text1;mso-themetint:191'>logo</span><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:Tahoma;
mso-bidi-font-family:Tahoma;color:#404040;mso-themecolor:text1;mso-themetint:
191'>”</span><span style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
Tahoma;mso-bidi-font-family:Tahoma;color:#333333'>、“<span lang=EN-US>yinzhilv.com</span>”等组合为广东<span
class=GramE>胤</span>之<span class=GramE>旅科技</span>有限公司<b style='mso-bidi-font-weight:
normal'>注册商标</b>，任何人不得擅自使用，否则将依法追究法律责任。</span><span lang=EN-US
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:Tahoma;
mso-bidi-font-family:Tahoma;color:#7F7F7F;mso-themecolor:text1;mso-themetint:
128'><o:p></o:p></span></p>

<p style='text-indent:21.0pt;line-height:18.75pt;mso-pagination:widow-orphan'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:Tahoma;
mso-bidi-font-family:Tahoma;color:#333333'>本网站高度重视知识产权保护并遵守中国各项知识产权法律、法规和具有约束力的规范性文件，尊重著作权人的合法权益。根据我国法律的有关规定，本网站制定了如下旨在保护权利人合法权益的措施和步骤，<b
style='mso-bidi-font-weight:normal'>当权利人发现本网站用户上传的内容侵犯其合法权益时，权利人应事先<span
class=GramE>向发出</span>书面权利通知，本网站将根据中国法律法规的有关规定采取措施及时移除相关内容</b>。<span
lang=EN-US><o:p></o:p></span></span></p>

<p style='text-indent:21.0pt;line-height:18.75pt;mso-pagination:widow-orphan'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:Tahoma;
mso-bidi-font-family:Tahoma;color:#333333'>为保证本网站有效处理用户发送的“权利通知”，请用户确保权利通知应包括以下内容：<span
lang=EN-US>A.</span>具体的联络信息，包括权利人的姓名<span lang=EN-US>(</span>名称<span
lang=EN-US>)</span>、通信地址、电话号码、身份证<span lang=EN-US>/</span>护照复印件<span
lang=EN-US>(</span>自然人<span lang=EN-US>)</span>、单位资质证明复印件<span lang=EN-US>;B.</span>要求删除的作品或文字名称及确切网络地址<span
lang=EN-US>;C.</span>构成侵权的初步证明材料，包括但不限于权利人对涉嫌侵权作品或内容拥有合法权利的权属证明文件、涉嫌侵权事实等<span
lang=EN-US>;D.</span>权利通知需由权利人或其合法授权人亲笔签名，<span class=GramE>若权利</span>人为单位则需加盖单位公章。<span
lang=EN-US><o:p></o:p></span></span></p>

<p style='text-indent:21.0pt;line-height:18.75pt;mso-pagination:widow-orphan'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:Tahoma;
mso-bidi-font-family:Tahoma;color:#333333'>用户应对权利通知内容的真实性负责。用户应了解，若通知书的内容不真实，用户将承担由此造成的全部法律责任。本网站在收到符合条件的权利通知后，将根据中国法律法规及规范性文件采取措施立即删除相关涉嫌侵权内容，<b
style='mso-bidi-font-weight:normal'>并将相应权利通知转送作品或内容提供者，<span class=GramE>胤</span>之<span
class=GramE>旅科技</span>有限公司不承担相关的侵权责任</b>。<span lang=EN-US><o:p></o:p></span></span></p>

<p style='text-indent:21.0pt;line-height:18.75pt;mso-pagination:widow-orphan'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:Tahoma;
mso-bidi-font-family:Tahoma;color:#333333'>通知到达地址及联系方式：<span lang=EN-US>A.</span>公司名称为广东<span
class=GramE>胤</span>之<span class=GramE>旅科技</span>有限公司<span lang=EN-US>;B.</span>邮寄地址为广东省中山市火炬开发区会展东路<span
lang=EN-US>1</span>号<span class=GramE>德仲广场</span><span lang=EN-US>2509;C.</span>企业邮箱及电话：</span><span
lang=EN-US style='font-size:10.5pt'><a href="mailto:huangxiaohua@yinzhilv.com"><span
style='font-family:仿宋_GB2312;mso-hansi-font-family:Tahoma;mso-bidi-font-family:
Tahoma;color:black;mso-themecolor:text1;text-decoration:none;text-underline:
none'>huangxiaohua@yinzhilv.com</span></a></span><span style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:Tahoma;mso-bidi-font-family:Tahoma;
color:#333333'>、<span lang=EN-US>18924900395</span>、<span lang=EN-US>0760-8670-3888</span>。</span><span
lang=EN-US style='font-family:仿宋_GB2312;mso-hansi-font-family:Tahoma;
mso-bidi-font-family:Tahoma;color:#333333'><o:p></o:p></span></p>

</div>`
export default text


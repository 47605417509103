var text = `<div class=WordSection1>

<p class=MsoNormal align=center style='margin-right:.3pt;text-align:center;
line-height:20.0pt'><span class=GramE><b><span style='font-size:16.0pt;
font-family:"微软雅黑",sans-serif;mso-bidi-font-family:宋体;color:black'>网站声明</span></b></span><b><span
style='font-size:16.0pt;font-family:"微软雅黑",sans-serif;mso-bidi-font-family:
宋体;color:black'></span></b><span lang=EN-US style='font-size:13.5pt;
font-family:"微软雅黑",sans-serif;mso-bidi-font-family:宋体;color:black'><o:p></o:p></span></p>

<p class=MsoNormal align=center style='margin-right:18.3pt;text-align:center;
line-height:20.0pt;mso-line-height-rule:exactly'><span style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>最新版本生效日期：<span lang=EN-US>2020</span>年<span
lang=EN-US>5</span>月<span lang=EN-US>8</span>日<span lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal align=center style='margin-right:18.3pt;text-align:center;
mso-line-height-rule:exactly;border-bottom:1px solid #dfdfdf;'>
<span style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>
<br/></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑;mso-bidi-font-family:黑体'>亲爱的用户，在<span class=GramE>您注册为胤</span>之旅网站<b
style='mso-bidi-font-weight:normal'>用户（下称“您”）</b>的过程中，您需要完成我们的注册流程并通过<b
style='mso-bidi-font-weight:normal'>点击同意</b>的形式<span class=GramE>在线签署</span>以下协议，请您务必仔细阅读、充分理解协议中的条款内容后再<b
style='mso-bidi-font-weight:normal'>点击同意</b>，<b style='mso-bidi-font-weight:
normal'>尤其是与您约定免除或限制责任的条款以及加粗字体</b>。</span><span lang=EN-US style='font-size:
10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-right:13.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>要访问和使用<span class=GramE>胤</span>之旅网站（</span><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑;mso-bidi-font-family:Arial'>www.yinzhilv.com</span><span style='font-size:
10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:
宋体'>），<b style='mso-bidi-font-weight:normal'>您必须不加修改地完全接受本协议中所包含的条款、条件以及与本网站签订的相关合作协议，并且遵守有关互联网相关法律、规定及本网站规则</b>。一旦您访问、使用了<span
class=GramE>胤</span>之旅网站，即表示您同意并接受了所有该等条款、条件及通告。同时，<b style='mso-bidi-font-weight:
normal'>在发出相关协议被修改的通知后，如果您继续使用<span class=GramE>胤</span>之旅网站，即视为您已经接受并同意遵守修改后的协议</b>。<span
lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-right:13.3pt;text-align:justify;text-justify:
inter-ideograph;line-height:20.0pt;mso-line-height-rule:exactly'><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-right:13.3pt;text-align:justify;text-justify:
inter-ideograph;line-height:20.0pt;mso-line-height-rule:exactly'><b
style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>1.</span></b><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑'>资讯声明<span lang=EN-US><o:p></o:p></span></span></b></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>本网站上<span class=GramE>胤</span>之旅网站的用户信息（包括但不限于公司名称、联系人及联络信息，产品的描述和说明，相关图片、视讯等）均由用户自行提供上传，用户依法应对其提供的任何信息承担全部责任。<span
class=GramE><b style='mso-bidi-font-weight:normal'>胤</b></span><b
style='mso-bidi-font-weight:normal'>之旅网站对此等信息的准确性、完整性、合法性或真实性均不承担任何责任</b>。此外，<span
class=GramE>胤</span>之旅网站对任何使用或提供本网站信息的商业活动及其风险不承担任何责任。</span><span lang=EN-US
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-right:13.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>浏览者可以下载本网站上显示的资料，但这些资料只限用于个人学习研究使用，不得用于任何商业用途，无论是否在资料上明示，所有此等资料都是受到版权法的法律保护。浏览者没有获得<span
class=GramE>胤</span>之旅网站或各自的版权所有者明确的书面同意下，不得分发、修改、散布、再使用、再传递或使用本网站的内容用于任何公众商业用途。</span><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-right:13.3pt;text-align:justify;text-justify:
inter-ideograph;line-height:20.0pt;mso-line-height-rule:exactly'><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-right:13.3pt;text-align:justify;text-justify:
inter-ideograph;line-height:20.0pt;mso-line-height-rule:exactly'><b
style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>2.</span></b><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑'>免责声明<span lang=EN-US><o:p></o:p></span></span></b></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span class=GramE><span style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>胤</span></span><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>之旅提醒您：在使用<span
class=GramE>胤</span>之<span class=GramE>旅服务</span>之前，请您务必仔细阅读并透彻理解本免责说明。您可以主动取消或停止使用<span
class=GramE>胤</span>之旅提供的服务，但<b style='mso-bidi-font-weight:normal'>如果您使用<span
class=GramE>胤</span>之旅服务，您的使用行为将被视为对本免责说明全部内容的认可</b>。<span lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑'>您完全理解并同意，鉴于<span class=GramE>胤</span>之旅以非人工检索方式、根据您键入的关键字或点击特定的产品或服务关键字自动生成的网页链接或相关的产品信息描述，例如价格、库存等，上述非人工检索方式，因缓存时间间隔或检索方式非完全智能等原因，有可能造成信息更新不及时或产品服务信息聚合、抽取不精准等瑕疵，<b
style='mso-bidi-font-weight:normal'>您完全理解并豁免上述产品或服务瑕疵给您造成的不便，<span class=GramE>胤</span>之旅不承担任何责任。</b><span
lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑'>为方便您的使用，<span class=GramE>胤</span>之<span
class=GramE>旅可能</span>会对产品的可信赖程度而进行的评级、推荐或风险<span class=GramE>提示仅</span>供您参考，<span
class=GramE><b style='mso-bidi-font-weight:normal'>胤</b></span><b
style='mso-bidi-font-weight:normal'>之旅<span class=GramE>不</span>担保该等评级、推荐或风险提示的准确性和完整性，对推荐的网站的内容及服务亦不承担任何责任</b>。<span
lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑'>您完全理解并同意，您通过<span class=GramE>胤</span>之<span
class=GramE>旅购买</span>机票、酒店等现有旅游产品或服务或此后新的产品和服务，应按照相关网页中展示的说明、规定或政策等履行相关义务，享有相关权利，<b
style='mso-bidi-font-weight:normal'>该等说明、规定或政策等与<span class=GramE>本说明</span>共同构成您和<span
class=GramE>胤</span>之旅的整体协议，您必须严格遵守</b>。<span lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑'>您了解并理解，任何<span class=GramE>经由本服务</span>发布的图形、图片或个人言论等，均表示了内容提供者、服务使用者个人的观点、观念和思想，并不代表<span
class=GramE>胤</span>之旅的观点或主张，对于在享受网络服务的过程中可能会接触到令人不快、不适当等内容的，<b
style='mso-bidi-font-weight:normal'>由您个人自行加以判断并承担所有风险，<span class=GramE>胤</span>之旅不承担任何责任</b>。<span
lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span class=GramE><span style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>胤</span></span><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>之旅为所有产品及服务提供方、全球合作伙伴（包括但不限于机票、住宿等）提供一个在线网站，供其宣传自己可供预订的产品及服务，
同时也通过本<span class=GramE>网站让</span>用户预订旅行资源。例如，<span class=GramE>胤</span>之旅网站上的临时住宿产品及服务信息（包括但不限于住宿方名称、联系人及联络信息、住宿场所的描述和说明、相关图片、视频等）
均由住宿方自行提供并上传，由住宿提供方对其提供并上传的所有信息承担相应法律责任。各住宿提供方均能通过后台自行更新其房价、可预订客房数量以及显示在<span
class=GramE>胤</span>之旅网站上的其它信息。<span lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑'>您完全理解并同意，通过<span class=GramE>胤</span>之<span
class=GramE>旅购买</span>机票、酒店、旅游度假、火车票、景点门票等现有旅游产品或服务或此后新的产品和服务时，您在预订产品或服务后应当及时付款；<b
style='mso-bidi-font-weight:normal'>您在预订产品或服务后未全额支付前，您尚未完成购买上述产品或服务。 因上述产品或服务的价格、舱位、数量或库存等实时更新或变化而造成您的额外费用、损失或无库存等，由您自行承担，<span
class=GramE>胤</span>之旅不承担任何责任</b>。您完全理解并同意，您在支付过程中，因网络中断、银行反馈信息错误等非<span
class=GramE>胤</span>之旅的原因造成的付款失败，由您自行承担，<span class=GramE>胤</span>之旅不承担任何责任。<span
lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>请您及时保存或备份您的文字、图片等其他信息，您完全理解并同意，由于<span
class=GramE>胤</span>之旅储存设备有限、设备故障、设备更新或设备受到攻击等设备原因或人为原因， 您使用网路服务储存的信息或数据等全部或部分发生删除、毁损、灭失或无法恢复的风险，均由您自行承担，<span
class=GramE>胤</span>之旅不承担任何责任</span></b><span style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>。<span lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span class=GramE><span style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>胤</span></span><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>之旅通过网站<span
lang=EN-US>www.yinzhilv.com</span>为您提供一个网站，您可藉由该网站浏览不同类型的旅游产品并进行预订。您通过网站进行预订，<span
class=GramE>代表您</span>愿意按照所列预订条款执行并愿意遵守网站规定的其他条件，如果<span class=GramE>胤</span>之<span
class=GramE>旅系统</span>接受了预订，并向您显示预订成功的提示，则成立对双方都有约束力的合约。如果我们接受了您预订或者预订不成功，我们保留下文所述<span
class=GramE>之拒绝</span>预订的权利：<span lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span class=GramE><span style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>胤</span></span><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>之<span
class=GramE>旅根据</span>全球合作伙伴提供给我们的信息向<span class=GramE>您发布</span>相关产品信息，从而提供服务。<b
style='mso-bidi-font-weight:normal'>虽然我们在提供服务时会做出合理管理，但是网站无法完全对信息核查也无法百分<span
class=GramE>百保证</span>信息的准确性、完整性、真实性和时效性，如<span class=GramE>遇市场</span>行情或环境的突然变化可能会导致网站上发布的信息不够准确或者不合时宜，所以不会对错误（包括明显的错误和印刷错误）、中断（可能因网站的故障、升级或维护或其他原因）、不准确、误导性或不真实的信息或<span
class=GramE>未发布</span>的<span class=GramE>的</span>信息负责</b>。如遇问题，我们的客户服务团队会做出合理的协助。<span
lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span class=GramE><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'>胤</span></b></span><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>之旅网站列出的旅游产品，其并不意味着也不应该被视作推荐该产品或者为该产品（质量、服务水平或评级）背书</span></b><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>。我们在此申明我们不代表、保证或承诺网站所列产品的质量、状况或适宜性。<b
style='mso-bidi-font-weight:normal'>网站保留以任何（合法）理由拒绝接受客户预订（或在特殊情况取消已经确认的预订）的权利，并无需对其加以证明。</b>通常拒绝客户或预订的理由包括但不限于：服务提供方的要求、违反服务条款、贸易或经济制裁、禁令、法律限制、（被怀疑）诈骗或偷窃、刑事案件嫌疑犯、可疑预订、客户提交误导或错误信息、行为不当、拒绝提供信息、沟通障碍、明显错误（见下文）、历史记录、列入政府或国际组织的防范名单中等。如果<span
class=GramE>胤</span>之<span class=GramE>旅拒绝</span>或者取消预订，而您已支付了费用，我们将退还所有的预订费用给您。<b
style='mso-bidi-font-weight:normal'>我们也保留自由决定拒绝用户暂时或永久访问网站（“将其列入防范名单”）的权利</b>。<span
lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑'>我们偶尔以“明显错误”为由取消或拒绝预订。为清晰起见，明显的错误是指一般认为不应该在网站上出现的错误（例如，有关于异常的价格）。在这种情况下，我们会将已收费用退还给客户。<span
class=GramE><b style='mso-bidi-font-weight:normal'>胤之旅能全权决定是否因该理由取消或拒绝预订</b></span>。<span
lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑'>用户需要妥善保管在<span class=GramE>胤</span>之旅网站的用户名、密码以及相关口令和信息，<b
style='mso-bidi-font-weight:normal'>如因用户保管不当造成的用户名、密码或相关资金被盗用而给用户造成的损失，<span
class=GramE>胤</span>之旅不承担相应责任</b>。<span lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;line-height:20.0pt;mso-line-height-rule:exactly'><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;line-height:20.0pt;mso-line-height-rule:exactly'><b
style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>3.</span></b><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑'>限制责任<span lang=EN-US><o:p></o:p></span></span></b></p>

<p class=MsoNormal style='margin-right:18.25pt;mso-para-margin-right:1.66gd;
text-align:justify;text-justify:inter-ideograph;text-indent:21.0pt;mso-char-indent-count:
2.0;line-height:20.0pt;mso-line-height-rule:exactly'><span style='font-size:
10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:
宋体'>您必须保证只将<span class=GramE>胤</span>之旅网站用于合法目的。不允许在没有著作权、商标权或其他专有权权利人明确许可的情况下，发布或者通过<span
class=GramE>胤</span>之旅网站传播任何可能侵犯他人权利的</span><span style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>任何的信息、产品、技术、程序、页面（包括但不限于页面设计及内容）以及资料内容（包括但不限于本站所刊载的图片、视频、<span
lang=EN-US>Flash</span>等）</span><span style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>，<b style='mso-bidi-font-weight:
normal'>否则<span class=GramE>您承担</span>由此产生的全部责任</b></span><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑;mso-bidi-font-family:Arial'>,</span></b><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>同时，<span
class=GramE>胤</span>之旅网站保留在收到权利人通知后删除网站内上述违规内容而不需通知用户的权利</span></b><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:宋体'>。</span><span lang=EN-US style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;line-height:20.0pt;mso-line-height-rule:exactly'><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:24.0pt;line-height:20.0pt;mso-line-height-rule:
exactly'><span class=GramE><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:宋体'>胤</span></b></span><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑;mso-bidi-font-family:宋体'>之旅<span class=GramE>网站仅</span>提供发布信息的空间。对这些信息不进行任何的控制，因此不对内容的准确性、完整性、有效性做任何保证；无论在任何原因下（包括但不限于疏忽原因），都不对因信赖<span
class=GramE>胤</span>之旅网站而通过使用本网站上的信息或由本网站链接的信息，或其他与本网站链接的网站信息所导致的损失或损害（包括直接、间接、特别或后果性的损失或损害，例如电脑系统之损坏或数据丢失等后果）引起的任何损失承<a
name=page2></a>担责任</span></b><span style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>。</span><span lang=EN-US
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:24.0pt;line-height:20.0pt;mso-line-height-rule:
exactly'><span style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑;mso-bidi-font-family:宋体'>广东<span class=GramE>胤</span>之<span class=GramE>旅科技</span>有限公司不保证<span
class=GramE>胤</span>之旅网站是不受干扰的、没有故障的，也不对使用效果做任何保证。<b style='mso-bidi-font-weight:
normal'>您同意独立承担因网站意外中断、操作或传输的迟延、电脑病毒、网络连接故障、未经授权的进入等引起的损失</b>。</span><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:24.0pt;line-height:20.0pt;mso-line-height-rule:
exactly'><span style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑;mso-bidi-font-family:宋体'>因违反<span class=GramE>胤</span>之旅网站的隐私策略、用户协议或其他有关的法律、法规，而导致有第三方对广东<span
class=GramE>胤</span>之<span class=GramE>旅科技</span>有限公司提起诉讼或索赔要求时，您同意赔偿广东<span
class=GramE>胤</span>之<span class=GramE>旅科技</span>有限公司因此遭受的所有损失。</span><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;line-height:20.0pt;mso-line-height-rule:exactly'><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;line-height:20.0pt;mso-line-height-rule:exactly'><b
style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>4.</span></b><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑'>隐私政策<span lang=EN-US><o:p></o:p></span></span></b></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><span style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>隐私政策明确了我们产品</span><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑;mso-bidi-font-family:Arial'>/</span><span style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>服务收集、使用及共享个人信息的类型和方式及用途。</span><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><span style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>（一）隐私政策的确认</span><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-right:19.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>尊重用户个人隐私是广东<span
class=GramE>胤</span>之<span class=GramE>旅科技</span>有限公司的基本政策。用户通过完成注册程序并点击</span><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:Arial'>“</span><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>注册</span><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:Arial'>”</span><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>的按钮，表示用户明确知晓本隐私政策所列事实，并与广东<span
class=GramE>胤</span>之<span class=GramE>旅科技</span>有限公司达成协议并接受所有的服务条款。我们理解您通过网络向我们提供信息是建立在对我们信任的基础上，我们重视这种信任，也深知隐私对您的重要性，并尽最大努力全力保护您的隐私。</span><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑;mso-bidi-font-family:宋体'>（二）信息收集</span></b><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑'><o:p></o:p></span></b></p>

<p class=MsoNormal style='text-indent:20.6pt;mso-char-indent-count:1.96;
line-height:15.75pt'><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:宋体'>为了更好地为您提供服务，<span class=GramE>胤</span>之旅网站将遵循</span></b><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:Arial'>“</span></b><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>合理、相关、必要</span></b><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:Arial'>”</span></b><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>原则，且以您同意为前提收集信息，不会收集法律法规禁止收集的信息。考虑到服务的重要性，您同意广东<span
class=GramE>胤</span>之<span class=GramE>旅科技</span>有限公司将会收集您在<span class=GramE>胤</span>之旅网站上输入的或以其他方式提供给我们的所有信息，包括但不限于以下：<span
lang=EN-US>a.</span></span></b><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>在<span
class=GramE>您注册</span>为用户时，需提供用户名、公司名称、手机号、联系人、座机、邮箱等信息；<span lang=EN-US>b.</span></span></b><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>在您后续更新个人信息时，需符合及时、详尽、准确的要求；<span
lang=EN-US>c.</span>在您预订机票或其他关联业务的时候，需提供乘客姓名、性别、生日、证件号以及联系人姓名、手机号、邮箱及相关地址等信息；<span
lang=EN-US>d.</span>在支付环节，需提供您的</span></b><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体;
mso-bidi-font-family:宋体;color:black'>银行卡号或网络支付账号；<span lang=EN-US>e.</span>为便于向您提供订单管理服务，我们会收集您在使用我们服务过程中产生的订单信息；<span
lang=EN-US>f.</span>其他与彼此合作相关的信息，如您的登陆设备信息、登陆<span lang=EN-US>IP</span>地址、访问日志等。您在网站提供这些信息之前，您需确保您已经取得合法的授权。</span></b><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
宋体;mso-bidi-font-family:宋体'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><span style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>（三）信息使用</span><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><span style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>一般来说，网站出于以下目的收集、使用您的信息：</span><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑;mso-bidi-font-family:Arial'>1</span></b><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:Arial'>）</span></b><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>向您提供服务：我们使用您的个人数据完成并<span
class=GramE>管理您</span>的网上预订或其他关联业务。另网站也会通过关联公司、业务合作伙伴和第三方为您提供相关的产品和服务。</span></b><b
style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'><o:p></o:p></span></b></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><span lang=EN-US
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:Arial'>2</span><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:Arial'>）</span><span
class=GramE><span style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑;mso-bidi-font-family:宋体'>帐号</span></span><span style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>管理：我们将使用您所提供的信息来<span
class=GramE>管理您</span>的<span class=GramE>帐号</span>，并为您提供一系列的实用功能。</span><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><span lang=EN-US
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:Arial'>3</span><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:Arial'>）</span><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:宋体'>与您联系：回复及处理您所提出的疑问或要求、发送与订单相关的信息。</span><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><span lang=EN-US
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:Arial'>4</span><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:Arial'>）</span><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>日常运营：包括但不限于订单管理、订单支付、客户验证、技术支持、网络维护、故障排除、数据汇总与分析、内部行政事务及内部政策和程序、生成内部报告、外部投资策略等</span></b><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:宋体'>。</span><span lang=EN-US style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><span lang=EN-US
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:Arial'>5</span><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:Arial'>）</span><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:宋体'>履行义务：处理相关政策下发生的保险索赔和付款，处理支付给合作伙伴的佣金或对服务合作伙伴造成的损失提起索赔或收回付款等。</span><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><span lang=EN-US
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:Arial'>6</span><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:Arial'>）</span><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:宋体'>法律目的：我们可能需要使用您的信息来处理和解决法律纠纷，或遵循对我们具有约束力的任何法律法规或监管机构颁发的文件规定，以配合国家部门或监管机构调查和遵从法律法规的目的。</span><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><span lang=EN-US
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:Arial'>7</span><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:Arial'>）</span><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:宋体'>此外，我们不会公开、编辑或透露用户的补充资料及保存在<span class=GramE>胤</span>之<span
class=GramE>旅用户</span>服务中的非公开内容，除非有法律许可要求或广东<span class=GramE>胤</span>之<span
class=GramE>旅科技</span>有限公司在诚信的基础上认为透露这些信息在以下三种情况是必要的：<span lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><span lang=EN-US
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:宋体'>7.1</span><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>）遵守有关法律规定，遵从<span
class=GramE>胤</span>之<span class=GramE>旅用户</span>合法服务程序。<span lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><span lang=EN-US
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:宋体'>7.2</span><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>）保持维护<span
class=GramE>胤</span>之旅的商标所有权。<span lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><span lang=EN-US
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:宋体'>7.3</span><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>）在紧急情况下竭力维护用户和社会大众的隐私安全。<span
lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><span lang=EN-US
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:宋体'>7.4</span><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>）符合其他相关的要求。</span><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑;mso-bidi-font-family:宋体'>（四）信息共享、转让和公开披露</span></b><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑'><o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-right:19.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:20.6pt;mso-char-indent-count:1.96;line-height:20.0pt;
mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:宋体'>在经过您的默认许可（即您向网站主动您及<span class=GramE>您客户</span>的信息）下，我们可能会向合作伙伴等第三方共享您的订单信息、账户信息等，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。<span
lang=EN-US><o:p></o:p></span></span></b></p>

<p class=MsoNormal style='margin-right:19.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:20.6pt;mso-char-indent-count:1.96;line-height:20.0pt;
mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>我们将会与关联公司共享：为便于我们向您提供产品和服务，推荐您可能感兴趣的信息，识别会员账号异常，保护关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司和<span
lang=EN-US>/</span>或其指定的服务提供商共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。<span
lang=EN-US><o:p></o:p></span></span></b></p>

<p class=MsoNormal style='margin-right:19.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:20.6pt;mso-char-indent-count:1.96;line-height:20.0pt;
mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>与授权合作伙伴共享：我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能，我们仅会出于本隐私权政策声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的。<span
lang=EN-US><o:p></o:p></span></span></b></p>

<p class=MsoNormal style='margin-right:19.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:20.6pt;mso-char-indent-count:1.96;line-height:20.0pt;
mso-line-height-rule:exactly'><span style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑'>目前，我们的授权合作伙伴包括以下类型：（<span lang=EN-US>1</span>）广告、分析服务类的授权合作伙伴（如有）。除非得到您的许可，否则我们不会将您的个人身份信息与提供广告、分析服务的合作伙伴共享。我们会向这些合作伙伴提供有关其广告覆盖面和有效性的信息，但不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会<span
class=GramE>识别您</span>个人。这类合作伙伴可能将上述信息与他们合法获取的其他数据相结合，以进行广告或决策建议；（<span
lang=EN-US>2</span>）服务提供商和其他合作伙伴（如有）。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查<span
lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-right:19.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:20.6pt;mso-char-indent-count:1.96;line-height:20.0pt;
mso-line-height-rule:exactly'><span style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑'>我们会与其约定严格的数据保护措施，令其按照我们的说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。<span
lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑;mso-bidi-font-family:宋体'>（五）<span lang=EN-US>Cookie</span>的使用<span
lang=EN-US><o:p></o:p></span></span></b></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'><span
style='mso-spacerun:yes'>&nbsp;&nbsp;&nbsp; </span></span></b><span lang=EN-US
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:宋体'>Cookie</span><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>是网页服务器放在您访问设备上的文本文件，会帮助您在后续访问时调用信息，简化记录您填写个人信息的流程。您有权接受或拒绝<span
lang=EN-US>Cookie</span>，如果浏览器自动接收<span lang=EN-US>Cookie</span>，您可以根据自己的需要修改浏览器的设置以拒绝<span
lang=EN-US>Cookie</span>。请注意，如果您选择拒绝<span lang=EN-US>Cookie</span>，那么您可能无法完全体验网站提供的服务。<span
lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
text-indent:20.6pt;mso-char-indent-count:1.96;line-height:20.0pt;mso-line-height-rule:
exactly'><b style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>我们将在您使用<span
class=GramE>胤</span>之旅网站服务过程中，自动收集您的个人信息：<span lang=EN-US>(1)</span>日志信息，指您使用我们的服务时，系统可能通过<span
lang=EN-US>cookies</span>、<span class=SpellE><span lang=EN-US>webbeacon</span></span>或其他方式自动采集的信息，包括设备信息或软件信息，例如您的<span
lang=EN-US>IP</span>地址和通过网页浏览器、移动设备用于接入我们服务的配置信息；<span lang=EN-US>(2)</span>在使用我们服务时搜索或浏览的信息，例如您使用的网页或访问页面，以及您在使用我们服务时浏览或提供的其他信息。<span
lang=EN-US><o:p></o:p></span></span></b></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑;mso-bidi-font-family:宋体'>（六）隐私政策的修改<span lang=EN-US><o:p></o:p></span></span></b></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;mso-line-height-rule:
exactly'><span class=GramE><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:宋体'>胤</span></b></span><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑;mso-bidi-font-family:宋体'>之旅会在必要时修改隐私政策，请您理解，我们可能会适时修订本隐私政策，我们将标注本隐私政策最近更新的日期，
更新将于发布时生效。如有必要，我们会与您联系，请您经常回访本隐私政策，以阅读最新版本。<span lang=EN-US><o:p></o:p></span></span></b></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph;
line-height:20.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>5.</span></b><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>法律</span></b><b
style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'><o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>本网站声明要与该国的国家法律解析一致，包括法律条款中有争议抵触的内容。用户和广东<span
class=GramE>胤</span>之<span class=GramE>旅科技</span>有限公司一致同意服从仲裁管辖。若有任何条款与法律相抵触，那这些条款将按尽可能接近的方法重新解析，而其它条款则保持对用户产生法律效力和影响，因用户解释本声明或通过<span
class=GramE>胤</span>之旅网站预订任何产品而导致的争议，将同意接受<b style='mso-bidi-font-weight:normal'>广州仲裁委员会中山分会</b>的管辖。</span><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-right:13.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span style='font-size:10.5pt;font-family:仿宋_GB2312;
mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>如果您不同意上述任何条款约定，请<span
class=GramE>您停止</span>注册。如<b style='mso-bidi-font-weight:normal'>果您按照注册流程提示填写信息、阅读并</b></span><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:Arial'>“</span></b><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>点击同意</span></b><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:Arial'>”</span></b><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑;mso-bidi-font-family:宋体'>上述协议且完成全部注册流程后，即表示您已充分阅读、理解并接受协议的全部内容</span></b><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:宋体'>。</span><span lang=EN-US style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:宋体'>如继续</span></b><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:Arial'>“</span></b><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:宋体'>点击同意</span></b><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:Arial'>”</span></b><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑;
mso-bidi-font-family:宋体'>即<span class=GramE>代表您</span>已阅读并同意《网站声明》中的所列条款，并同意我们将您的订单信息共享给为完成此订单所必须的第三方合作方。广东<span
class=GramE>胤</span>之<span class=GramE>旅科技</span>有限公司保留对条款进行及时更改的权利及对条款的最终解释权。<span
lang=EN-US><o:p></o:p></span></span></b></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;line-height:20.0pt;mso-line-height-rule:exactly'><span
lang=EN-US style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑;mso-bidi-font-family:宋体'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;line-height:20.0pt;mso-line-height-rule:exactly'><b
style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:10.5pt;
font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>6.</span></b><b
style='mso-bidi-font-weight:normal'><span style='font-size:10.5pt;font-family:
仿宋_GB2312;mso-hansi-font-family:微软雅黑'>其他<span lang=EN-US><o:p></o:p></span></span></b></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><span class=GramE><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'>胤</span></b></span><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>之旅<span
class=GramE>不</span>担保网络服务一定能满足用户的要求，也<span class=GramE>不</span>担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不做担保</span></b><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>。<span
lang=EN-US><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:15.75pt;mso-char-indent-count:1.5;line-height:20.0pt;
mso-line-height-rule:exactly'><span class=GramE><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:
微软雅黑'>胤</span></b></span><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>之旅不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由<span
class=GramE>胤</span>之<span class=GramE>旅实际</span>控制的任何网页上的内容，<span class=GramE>胤</span>之旅不承担任何责任。<span
lang=EN-US><o:p></o:p></span></span></b></p>

<p class=MsoNormal style='margin-right:18.3pt;text-align:justify;text-justify:
inter-ideograph;text-indent:21.0pt;mso-char-indent-count:2.0;line-height:20.0pt;
mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
style='font-size:10.5pt;font-family:仿宋_GB2312;mso-hansi-font-family:微软雅黑'>对于因不可抗力或<span
class=GramE>胤</span>之旅不能控制的原因造成的网络服务中断或其他缺陷，<span class=GramE>胤</span>之旅不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。<span
lang=EN-US><o:p></o:p></span></span></b></p>

</div>`
export default text